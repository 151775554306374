import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CloudIcon from '@mui/icons-material/Cloud';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://meteo.studio/">
        Meteo Studio
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const regions = [
  {
    slug: '_ijmg',
    name: 'IJsselmeer',
    bounds: "52°4.92'N 004°6.42'E — 53°24.96'N 005°59.64'E",
    image: 'static/images/ijmg.png',
  },
  {
    slug: '_wad',
    name: 'Waddenzee',
    bounds: "52°47.70'N 004°13.08'E — 53°52.56'N 007°32.88'E",
    image: 'static/images/wad.png',
  },
  {
    slug: '_zld',
    name: 'Zeeland',
    bounds: "51°2.82'N 002°53.16'E — 52°11.82'N 004°46.38'E",
    image: 'static/images/zld.png',
  },
  {
    slug: '_nl',
    name: 'Nederland',
    bounds: "52°4.92'N 004°6.42'E — 53°24.96'N 005°59.64'E",
    image: 'static/images/nl.png',
  },
  {
    slug: '_nz',
    name: 'Noordzee',
    bounds: "50°43.50'N 000°22.20'E — 53°52.56'N 004°48.60'E",
    image: 'static/images/nz.png',
  },
  {
    slug: '_db',
    name: 'Duitse Bocht',
    bounds: "52°58.74'N 004°17.52'E — 55°36.06'N 009°39.42'E",
    image: 'static/images/db.png',
  },
  {
    slug: '_dov',
    name: 'Dover',
    bounds: "49°19.32'N 000°0.00'E — 51°23.52'N 003°19.80'E",
    image: 'static/images/dov.png',
  },
  {
    slug: '',
    name: 'Modelgebied',
    bounds: "49°00.00'N 000°00.00'E — 55°52.62'N 011°03.78'E",
    image: 'static/images/harm.png',
  },
]


const theme = createTheme();

export default function App() {
  const [runtime, setRuntime] = useState('');
  const [updated, setUpdated] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('https://harmonie-files.s3.eu-west-1.amazonaws.com/download/latest.json');
      const {runtime = '', updated = '' } = await data.json()
      setRuntime(runtime);
      setUpdated(`${updated}Z`);
    }

    fetchData().catch(console.error);
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CloudIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Meteo Studio
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 6,
            pb: 4,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Meteo Studio
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              GRIB-files van het KNMI Harmonie-model, geschikt voor XyGrib
            </Typography>
          </Container>
        </Box>
        {/* End hero unit */}
        <Container sx={{ py: 4 }} maxWidth="md">
          <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            Model run: {runtime && `${runtime.replace('_', ' ')}:00 UTC`}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            Laaste update: {updated && `${new Date(updated).toLocaleDateString('en-CA', {timeZone: 'Europe/Amsterdam'})} ${new Date(updated).toLocaleTimeString('nl-NL', {timeZone: 'Europe/Amsterdam', timeZoneName: 'short'})}`}
          </Grid>
            {regions.map((region) => (
              <Grid item key={`card${region.slug}`} xs={12} sm={6} md={6}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <a id={`image_${runtime}${region.slug}`} href={`https://harmonie-files.s3.eu-west-1.amazonaws.com/download/harmonie_xy_${runtime}${region.slug}.grb`}>
                    <CardMedia
                      component="img"
                      image={region.image}
                      alt={`${region.name} map`}
                      width="400px"
                      height="400px"
                    />
                  </a>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {region.name}
                    </Typography>
                    <Typography>
                      {region.bounds}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <a id={`link_${runtime}${region.slug}`} href={`https://harmonie-files.s3.eu-west-1.amazonaws.com/download/harmonie_xy_${runtime}${region.slug}.grb`}>
                      <Button>Alle data</Button>
                    </a>
                    <a id={`wind_${runtime}${region.slug}`} href={`https://harmonie-files.s3.eu-west-1.amazonaws.com/download/harmonie_xy_${runtime}${region.slug}_wind.grb`}>
                    <Button>Alleen wind</Button>
                    </a>                    
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
